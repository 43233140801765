import { template as template_73f1cb8dc76c4d0c80f387406e8fe226 } from "@ember/template-compiler";
const FKText = template_73f1cb8dc76c4d0c80f387406e8fe226(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
