import { template as template_f4d15e5fa4454d6293909727502a89b7 } from "@ember/template-compiler";
const FKLabel = template_f4d15e5fa4454d6293909727502a89b7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
