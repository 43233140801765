import { template as template_05f2123d75de45159d740e242f7e8241 } from "@ember/template-compiler";
const FKControlMenuContainer = template_05f2123d75de45159d740e242f7e8241(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
