import { template as template_d6783046d6de42e6914de5f5c6433ec2 } from "@ember/template-compiler";
const EmptyState = template_d6783046d6de42e6914de5f5c6433ec2(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
