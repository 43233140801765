import { template as template_caec4ad4911d4071bc2e523e25b66bce } from "@ember/template-compiler";
const WelcomeHeader = template_caec4ad4911d4071bc2e523e25b66bce(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
