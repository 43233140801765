import { template as template_2da5a991f4964b418a010ad2b5d3b390 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import i18n from "discourse-common/helpers/i18n";
const UserSummarySection = template_2da5a991f4964b418a010ad2b5d3b390(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
